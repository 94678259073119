import { useEffect, useState } from 'react'
import { EventTypes, IDataLayerParams, IDataLayer } from './typesUseDataLayer'

/**
 *  Data Layer Event
 *  Saiba Mais: https://confluence.bancointer.com.br/pages/viewpage.action?pageId=188390035#Novopadr%C3%A3odetagueamento-PARADEVS
 */

const useDataLayer = (event?: EventTypes) => {
  const [ dataLayerEvent, setDataLayerEvent ] = useState<EventTypes>('ga_event_body')

  useEffect(() => {
    event && setDataLayerEvent(event)
  }, [ dataLayerEvent ])

  const returnDataLayer = (data: IDataLayerParams) => {
    const { section, section_name, element_action, element_name, element_previous, step, redirect_url, element_search }: IDataLayerParams = data

    const dataLayer: IDataLayer = {
      event: dataLayerEvent,
      params: {
        section: section,
        section_name: section_name || 'null',
        element_action: element_action,
        element_name: element_name,
        element_previous: element_previous || 'null',
        c_page: window.location.href,
        step: step || 'null',
        redirect_url: redirect_url || 'null',
        element_search: element_search || 'null',
      },
    }

    return dataLayer
  }

  const sendDatalayerEvent = (dataLayer: IDataLayerParams) => {
    const dataLayerEvent = returnDataLayer(dataLayer)

    if (window && window.dataLayer) {
      window.dataLayer.push(dataLayerEvent)
    }

    return window.dataLayer
  }

  return [ sendDatalayerEvent ]
}

export default useDataLayer
